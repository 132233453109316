import {
  AfterViewInit,
  Component,
  ViewChild,
  Output,
  EventEmitter,
  Input,
} from '@angular/core'

import { Recipe } from '../../models/recipe.model'
import { MatPaginator, PageEvent } from '@angular/material/paginator'
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators'
import { UntypedFormControl } from '@angular/forms'

@Component({
  selector: 'app-recipe-list',
  templateUrl: './recipe-list.component.html',
  styleUrls: ['./recipe-list.component.scss'],
})
export class RecipeListComponent implements AfterViewInit {
  displayedColumns: string[] = ['title', 'category_title', 'options']
  search = new UntypedFormControl('')

  @Input() pageIndex: number
  @Input() pageSize: number
  @Input() total: number
  @Input() recipes: Recipe[]

  @Output() pageChangeEvent = new EventEmitter<PageEvent>()
  @Output() searchChangeEvent = new EventEmitter<string>()

  @ViewChild(MatPaginator) paginator: MatPaginator

  ngAfterViewInit(): void {
    this.search.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((v) => this.searchChangeEvent.emit(v))

    this.paginator.page
      .pipe(tap((event: PageEvent) => this.pageChangeEvent.emit(event)))
      .subscribe()
  }
}
