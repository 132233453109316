<form [formGroup]="filterForm">
  <h2>Dates</h2>

  <mat-form-field appearance="fill">
    <mat-label>Start</mat-label>
    <input matInput [matDatepicker]="start" formControlName="start" (dateChange)="onFormChange()" />
    <mat-datepicker-toggle matSuffix [for]="start"></mat-datepicker-toggle>
    <mat-datepicker #start></mat-datepicker>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>End</mat-label>
    <input matInput [matDatepicker]="end" formControlName="end" (dateChange)="onFormChange()" />
    <mat-datepicker-toggle matSuffix [for]="end"></mat-datepicker-toggle>
    <mat-datepicker #end></mat-datepicker>
  </mat-form-field>

  <h2>
    Accounts
    <span style="font-size: small; font-weight: normal">(total {{ total }} &euro;)</span>
  </h2>

  <ul>
    <li *ngFor="let account of accounts; let i = index" formArrayName="accountIds">
      <input type="checkbox" [formControlName]="i" (change)="onFormChange()" />
      <mat-label>{{ account.title }} ({{ account.balance }} &euro;)</mat-label>
    </li>
  </ul>

  <h2>Categories</h2>
  <ul>
    <li *ngFor="let category of categories; let i = index" formArrayName="categoryIds">
      <input type="checkbox" [formControlName]="i" (change)="onFormChange()" />
      <mat-label>{{ category.title }}</mat-label>
    </li>
  </ul>
</form>
