<button mat-raised-button (click)="openPaymentDialog()">Add payment</button>

<table mat-table [dataSource]="payments">
  <ng-container matColumnDef="amount">
    <th mat-header-cell *matHeaderCellDef>Amount</th>
    <td mat-cell *matCellDef="let row" [ngClass]="parseFloat(row.amount) >= 0 ? 'positive' : 'negative'">
      {{ row.amount }} &euro;
    </td>
  </ng-container>

  <ng-container matColumnDef="title">
    <th mat-header-cell *matHeaderCellDef>Title</th>
    <td mat-cell *matCellDef="let row">{{ row.title }}</td>
  </ng-container>

  <ng-container matColumnDef="description">
    <th mat-header-cell *matHeaderCellDef>Description</th>
    <td mat-cell *matCellDef="let row">{{ row.description }}</td>
  </ng-container>

  <ng-container matColumnDef="happened_on">
    <th mat-header-cell *matHeaderCellDef>Date</th>
    <td mat-cell *matCellDef="let row">
      {{ row.happened_on | date: 'dd.MM.yyyy' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="account">
    <th mat-header-cell *matHeaderCellDef>Account</th>
    <td mat-cell *matCellDef="let row">{{ row.account.title }}</td>
  </ng-container>

  <ng-container matColumnDef="options">
    <th mat-header-cell *matHeaderCellDef>Options</th>
    <td mat-cell *matCellDef="let row">
      <a mat-button [routerLink]="['/payments/edit', row.id]" queryParamsHandling="merge">Edit</a>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>

<mat-paginator
  [pageIndex]="pageIndex"
  [pageSize]="pageSize"
  [pageSizeOptions]="[10, 20, 30]"
  [length]="total"
  showFirstLastButtons
>
</mat-paginator>
