import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material/form-field'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatTableModule } from '@angular/material/table'
import { HttpClientModule } from '@angular/common/http'
import { MatTreeModule } from '@angular/material/tree'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { FullCalendarModule } from '@fullcalendar/angular'
import { MatListModule } from '@angular/material/list'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatDialogModule } from '@angular/material/dialog'
import dayGridPlugin from '@fullcalendar/daygrid'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { RecipeListComponent } from './components/recipe-list/recipe-list.component'
import { RecipeDetailComponent } from './components/recipe-detail/recipe-detail.component'
import { SafeHtmlPipe } from './pipes/safe-html.pipe'
import { RecipeCategoryTreeComponent } from './components/recipe-category-tree/recipe-category-tree.component'
import { RecipesComponent } from './components/recipes/recipes.component'
import { RecipeEditComponent } from './components/recipe-edit/recipe-edit.component'
import { httpInterceptorProviders } from './http-interceptors'
import { EventsComponent } from './components/events/events.component'
import { PaymentsComponent } from './components/payments/payments.component'
import { PaymentListComponent } from './components/payment-list/payment-list.component'
import { InvoicesComponent } from './components/invoices/invoices.component'
import { InvoiceListComponent } from './components/invoice-list/invoice-list.component'
import { PaymentEditComponent } from './components/payment-edit/payment-edit.component'
import { PaymentFilterComponent } from './components/payment-filter/payment-filter.component'
import { BirthdaysComponent } from './components/birthdays/birthdays.component'
import { BirthdayListComponent } from './components/birthday-list/birthday-list.component'
import { EstatesComponent } from './components/estates/estates.component'
import { EstateListComponent } from './components/estate-list/estate-list.component'
import { DashboardComponent } from './components/dashboard/dashboard.component'
import { PaymentStatsComponent } from './components/payment-stats/payment-stats.component'
import { NgChartsModule } from 'ng2-charts'
import { MatNativeDateModule } from '@angular/material/core'
import { InvoiceDuplicateComponent } from './components/invoice-duplicate/invoice-duplicate.component'
import { PaymentAddComponent } from './components/payment-add/payment-add.component'

@NgModule({
  declarations: [
    AppComponent,
    RecipeListComponent,
    RecipeDetailComponent,
    SafeHtmlPipe,
    RecipeCategoryTreeComponent,
    RecipesComponent,
    EventsComponent,
    PaymentsComponent,
    PaymentListComponent,
    InvoicesComponent,
    InvoiceListComponent,
    PaymentEditComponent,
    PaymentFilterComponent,
    RecipeEditComponent,
    BirthdaysComponent,
    BirthdayListComponent,
    EstatesComponent,
    EstateListComponent,
    DashboardComponent,
    PaymentStatsComponent,
    InvoiceDuplicateComponent,
    PaymentAddComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatToolbarModule,
    MatPaginatorModule,
    MatTreeModule,
    MatIconModule,
    HttpClientModule,
    MatButtonModule,
    FullCalendarModule,
    MatFormFieldModule,
    MatInputModule,
    MatSidenavModule,
    MatListModule,
    FormsModule,
    ReactiveFormsModule,
    NgChartsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
  ],
  providers: [httpInterceptorProviders],
  bootstrap: [AppComponent],
})
export class AppModule {}
