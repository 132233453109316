import { Component, OnInit } from '@angular/core'
import { Location } from '@angular/common'
import { PageEvent } from '@angular/material/paginator'
import { ActivatedRoute, ParamMap, Router } from '@angular/router'
import { Recipe } from 'src/app/models/recipe.model'
import { Pagination } from 'src/app/pagination'
import { RecipeService, RecipesResponse } from 'src/app/services/recipe.service'

@Component({
  selector: 'app-recipes',
  templateUrl: './recipes.component.html',
  styleUrls: ['./recipes.component.scss'],
})
export class RecipesComponent implements OnInit {
  pageIndex: number
  pageSize: number
  total: number
  search: string
  categoryId: number
  recipes: Recipe[]

  constructor(
    private readonly location: Location,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private recipeService: RecipeService
  ) {}

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((params: ParamMap) => {
      this.pageIndex = Number(params.get('page')) || 0
      this.pageSize = Number(params.get('page_size')) || 30
      this.categoryId = Number(params.get('category_id'))
      this.search = params.get('search') || ''
      this.loadRecipes()
    })
  }

  updateSearch(search: string) {
    this.search = search
    this.loadRecipes()
  }

  updateCategoryId(categoryId: number) {
    this.categoryId = categoryId
    this.loadRecipes()
  }

  updatePage(pageEvent: PageEvent) {
    this.pageIndex = pageEvent.pageIndex
    this.pageSize = pageEvent.pageSize
    this.loadRecipes()
  }

  loadRecipes() {
    this.location.go(this.buildURL())
    this.getRecipes()
  }

  getRecipes(): void {
    this.recipeService
      .getRecipes(
        new Pagination(this.pageIndex + 1, this.pageSize),
        this.search,
        this.categoryId
      )
      .subscribe((resp: RecipesResponse) => {
        this.recipes = resp.recipes
        this.total = resp.count
      })
  }

  buildURL(): string {
    return this.router
      .createUrlTree([], {
        relativeTo: this.route,
        queryParams: {
          page: this.pageIndex,
          search: this.search,
          category_id: this.categoryId,
        },
        queryParamsHandling: 'merge',
      })
      .toString()
  }
}
