import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { PaymentFilter } from 'src/app/models/payment-filter.model'
import { Pagination } from 'src/app/pagination'
import { PaymentAccount } from '../../models/payment-account.model'
import { PaymentCategory } from '../../models/payment-category.model'
import {
  PaymentAccountsResponse,
  PaymentCategoriesResponse,
  PaymentsService,
} from '../../services/payments.service'

@Component({
  selector: 'app-payment-filter',
  templateUrl: './payment-filter.component.html',
  styleUrls: ['./payment-filter.component.scss'],
})
export class PaymentFilterComponent implements OnInit {
  filterForm: UntypedFormGroup
  categories: PaymentCategory[]
  accounts: PaymentAccount[]

  @Input() total: number
  @Input() start: Date
  @Input() end: Date
  @Input() accountIds: number[]
  @Input() categoryIds: number[]
  @Output() filterChangeEvent = new EventEmitter<PaymentFilter>()

  constructor(
    private fb: UntypedFormBuilder,
    private paymentsService: PaymentsService
  ) {}

  ngOnInit(): void {
    this.filterForm = this.fb.group({
      start: new UntypedFormControl(this.start),
      end: new UntypedFormControl(this.end),
    })
    this.getPaymentAccounts()
    this.getPaymentCategories()
  }

  onFormChange() {
    this.filterChangeEvent.emit(
      new PaymentFilter(
        this.filterForm.get('start').value,
        this.filterForm.get('end').value,
        this.getSelectedAccountIds(),
        this.getSelectedCategoryIds()
      )
    )
  }

  getSelectedAccountIds(): number[] {
    const res = []
    const values = this.filterForm.getRawValue()
    for (const [accIndex, accChecked] of Object.entries(values.accountIds)) {
      if (accChecked) {
        res.push(this.accounts[accIndex].id)
      }
    }
    return res
  }

  getSelectedCategoryIds(): number[] {
    const res = []
    const values = this.filterForm.getRawValue()
    for (const [catIndex, catChecked] of Object.entries(values.categoryIds)) {
      if (catChecked) {
        res.push(this.categories[catIndex].id)
      }
    }
    return res
  }

  getPaymentAccounts(): void {
    this.paymentsService
      .getPaymentAccounts(new Pagination(1, 50))
      .subscribe((resp: PaymentAccountsResponse) => {
        this.accounts = resp.accounts
        this.filterForm.addControl(
          'accountIds',
          this.fb.array(
            resp.accounts.map((acc: PaymentAccount) =>
              this.isSelectedAccount(acc)
            )
          )
        )
      })
  }

  isSelectedAccount(acc: PaymentAccount): number {
    for (const accId of this.accountIds) {
      if (accId == acc.id) {
        return 1
      }
    }
    return 0
  }

  getPaymentCategories(): void {
    this.paymentsService
      .getPaymentCategories(new Pagination(1, 50))
      .subscribe((resp: PaymentCategoriesResponse) => {
        this.categories = resp.categories
        this.filterForm.addControl(
          'categoryIds',
          this.fb.array(
            resp.categories.map((cat: PaymentCategory) =>
              this.isSelectedCategory(cat)
            )
          )
        )
      })
  }

  isSelectedCategory(cat: PaymentCategory): number {
    for (const catId of this.categoryIds) {
      if (catId == cat.id) {
        return 1
      }
    }
    return 0
  }
}
