import { Component, OnInit } from '@angular/core'
import { EventsResponse, EventsService } from 'src/app/services/events.service'
import { Event } from '../../models/event.model'

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  events: Event[]

  constructor(private eventsService: EventsService) {}

  ngOnInit(): void {
    this.getEvents()
  }

  getEvents(): void {
    const start = new Date()
    const end = new Date(start)
    end.setDate(end.getDate() + 7)

    this.eventsService
      .getEvents(start, end)
      .subscribe((resp: EventsResponse) => {
        this.events = resp.events
      })
  }
}
