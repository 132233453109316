<div class="grid grid-cols-12">
  <div class="col-span-12">
    <form [formGroup]="editForm" (ngSubmit)="onSubmit()">
      <mat-form-field appearance="fill">
        <mat-label>Title</mat-label>
        <input matInput formControlName="title" type="text" />
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Content</mat-label>
        <textarea matInput formControlName="content" rows="40"></textarea>
      </mat-form-field>

      <button type="submit" [disabled]="!editForm.valid">Submit</button>
    </form>
  </div>
</div>
