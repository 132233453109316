import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs'
import { ApiUtil } from '../api-util'
import { Event } from '../models/event.model'
import { Calendar } from '../models/calendar.model'

export class CalendarsResponse {
  calendars: Calendar[]
}

export class EventsResponse {
  events: Event[]
}

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  constructor(private http: HttpClient) {}

  getCalendars(): Observable<CalendarsResponse> {
    return this.http.get<CalendarsResponse>(
      ApiUtil.buildURL('/api/calendars'),
      ApiUtil.buildOptions()
    )
  }

  getEvents(start: Date, end: Date): Observable<EventsResponse> {
    return this.http.get<EventsResponse>(
      ApiUtil.buildURL('/api/events'),
      ApiUtil.buildOptions(
        new HttpParams()
          .set('start', start.toISOString())
          .set('end', end.toISOString())
      )
    )
  }
}
