import { HttpParams } from '@angular/common/http'

export class Pagination {
  constructor(private page: number, private perPage: number) {}

  public httpParams(): HttpParams {
    return new HttpParams()
      .set('page', this.page.toString())
      .set('per_page', this.perPage.toString())
  }
}
