<div class="grid grid-cols-12">
  <div class="col-span-2">
    <app-recipe-category-tree (categoryIdChangeEvent)="updateCategoryId($event)"></app-recipe-category-tree>
  </div>
  <div class="col-span-10">
    <app-recipe-list
      [recipes]="recipes"
      [pageIndex]="pageIndex"
      [pageSize]="pageSize"
      [total]="total"
      (searchChangeEvent)="updateSearch($event)"
      (pageChangeEvent)="updatePage($event)"
    ></app-recipe-list>
  </div>
</div>
