import { Component, OnInit } from '@angular/core'
import { UntypedFormBuilder } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { FormUtil } from 'src/app/form-util'
import { Invoice } from 'src/app/models/invoice.model'
import { InvoiceResponse, InvoiceService } from 'src/app/services/invoice.service'

@Component({
  selector: 'app-invoice-duplicate',
  templateUrl: './invoice-duplicate.component.html',
  styleUrls: ['./invoice-duplicate.component.scss'],
})
export class InvoiceDuplicateComponent implements OnInit {
  duplicateForm = this.fb.group({
    number: [''],
    issue_date: [''],
    due_date: [''],
    language: [''],
  })
  invoice: Invoice

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private fb: UntypedFormBuilder,
    private invoicesService: InvoiceService
  ) {}

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id')
    this.getInvoice(+id)
  }

  getInvoice(id: number): void {
    this.invoicesService.getInvoice(id).subscribe((resp: InvoiceResponse) => {
      this.invoice = resp.invoice
      this.fillForm()
    })
  }

  fillForm() {
    this.duplicateForm.patchValue({
      number: this.invoice.number,
      issue_date: this.invoice.issue_date,
      due_date: this.invoice.due_date,
    })
  }

  onSubmit() {
    const values = FormUtil.getValues(this.duplicateForm)
    this.invoicesService.duplicateInvoice(this.invoice.id, values).subscribe((res) => {
      this.router.navigate(['/invoices'], { queryParamsHandling: 'preserve' })
    })
  }
}
