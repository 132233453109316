import { HttpParams } from '@angular/common/http'
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { tap } from 'rxjs/operators'
import { ApiUtil } from 'src/app/api-util'
import { Invoice } from '../../models/invoice.model'
import { Pagination } from '../../pagination'
import { InvoiceService, InvoicesResponse } from '../../services/invoice.service'

@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss'],
})
export class InvoiceListComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['number', 'client_title', 'supplier_title', 'view', 'download', 'options']
  pageSize: number = 30
  total: number
  invoices: Invoice[]

  @ViewChild(MatPaginator) paginator: MatPaginator

  constructor(private invoiceService: InvoiceService) {}

  ngOnInit(): void {
    this.getInvoices(0, this.pageSize)
  }

  ngAfterViewInit(): void {
    this.paginator.page.pipe(tap(() => this.getInvoicesPage())).subscribe()
  }

  getInvoicesPage(): void {
    this.getInvoices(this.paginator.pageIndex, this.paginator.pageSize)
  }

  getInvoices(pageIndex: number, pageSize: number): void {
    this.invoiceService.getInvoices(new Pagination(pageIndex + 1, pageSize)).subscribe((resp: InvoicesResponse) => {
      this.invoices = resp.invoices
      this.total = resp.count
    })
  }

  renderInvoiceURL(id: number, lang: string): string {
    return ApiUtil.buildURL('/api/invoices/' + id + '/render', new HttpParams().set('language', lang))
  }

  downloadInvoiceURL(id: number, lang: string): string {
    return ApiUtil.buildURL('/api/invoices/' + id + '/download', new HttpParams().set('language', lang))
  }

  duplicateInvoiceURL(id: number): string {
    return ApiUtil.buildURL('/api/invoices/' + id + '/duplicate')
  }
}
