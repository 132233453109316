import { Component, OnInit } from '@angular/core'
import { Location } from '@angular/common'
import { PageEvent } from '@angular/material/paginator'
import { ActivatedRoute, ParamMap, Router } from '@angular/router'
import { PaymentFilter } from 'src/app/models/payment-filter.model'
import { Payment } from 'src/app/models/payment.model'
import { Pagination } from 'src/app/pagination'
import {
  PaymentsOptions,
  PaymentsResponse,
  PaymentsService,
  PaymentTotalOptions,
  PaymentTotalResponse,
} from 'src/app/services/payments.service'

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss'],
})
export class PaymentsComponent implements OnInit {
  pageIndex: number
  pageSize: number
  total: number
  start: Date
  end: Date
  categoryIds: number[]
  accountIds: number[]
  payments: Payment[]
  sum: number

  constructor(
    private readonly location: Location,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private paymentsService: PaymentsService
  ) {}

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((params: ParamMap) => {
      this.accountIds = this.getAccountIds(params)
      this.categoryIds = this.getCategoryIds(params)
      this.start = this.getStart(params)
      this.end = this.getEnd(params)
      this.pageIndex = Number(params.get('page')) || 0
      this.pageSize = Number(params.get('page_size')) || 30
      this.loadPayments()
    })
  }

  updateFilter(filter: PaymentFilter) {
    this.start = filter.start
    this.end = filter.end
    this.accountIds = filter.accountIds
    this.categoryIds = filter.categoryIds
    this.loadPayments()
  }

  updatePage(pageEvent: PageEvent) {
    this.pageIndex = pageEvent.pageIndex
    this.pageSize = pageEvent.pageSize
    this.loadPayments()
  }

  loadPayments() {
    this.location.go(this.buildURL())
    this.getPayments()
    this.getPaymentTotal()
  }

  getStart(params: ParamMap): Date {
    return this.getDateFromParam(params, 'start')
  }

  getEnd(params: ParamMap): Date {
    return this.getDateFromParam(params, 'end')
  }

  getCategoryIds(params: ParamMap): number[] {
    return this.getIdsFromParam(params, 'cat')
  }

  getAccountIds(params: ParamMap): number[] {
    return this.getIdsFromParam(params, 'acc')
  }

  getIdsFromParam(params: ParamMap, param: string): number[] {
    return (params.get(param) || '')
      .split(',')
      .filter((v) => v != '')
      .map((v) => Number(v))
      .filter((v) => !Number.isNaN(v))
  }

  getDateFromParam(params: ParamMap, param: string): Date {
    const s = params.get(param) || ''
    if (!s) {
      return null
    }
    const d = new Date(s)
    try {
      d.toISOString()
    } catch {
      return null
    }
    return d
  }

  getPayments(): void {
    this.paymentsService
      .getPayments(
        new PaymentsOptions(
          new Pagination(this.pageIndex + 1, this.pageSize),
          this.start,
          this.end,
          this.accountIds,
          this.categoryIds
        )
      )
      .subscribe((resp: PaymentsResponse) => {
        this.payments = resp.payments
        this.total = resp.count
      })
  }

  getPaymentTotal(): void {
    const now = new Date()
    this.paymentsService
      .getPaymentTotal(
        new PaymentTotalOptions(null, null, this.accountIds, this.categoryIds)
      )
      .subscribe((resp: PaymentTotalResponse) => {
        this.sum = resp.total
      })
  }

  buildURL(): string {
    const queryParams = {
      acc: this.accountIds.join(','),
      cat: this.categoryIds.join(','),
      page: this.pageIndex,
      start: this.start ? this.start.toISOString() : '',
      end: this.end ? this.end.toISOString() : '',
    }
    return this.router
      .createUrlTree([], {
        relativeTo: this.route,
        queryParams: queryParams,
        queryParamsHandling: 'merge',
      })
      .toString()
  }
}
