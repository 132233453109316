<table mat-table [dataSource]="invoices">
  <ng-container matColumnDef="number">
    <th mat-header-cell *matHeaderCellDef>Number</th>
    <td mat-cell *matCellDef="let element">{{ element.number }}</td>
  </ng-container>

  <ng-container matColumnDef="client_title">
    <th mat-header-cell *matHeaderCellDef>Client</th>
    <td mat-cell *matCellDef="let element">{{ element.client.title }}</td>
  </ng-container>

  <ng-container matColumnDef="supplier_title">
    <th mat-header-cell *matHeaderCellDef>Supplier</th>
    <td mat-cell *matCellDef="let element">{{ element.supplier.title }}</td>
  </ng-container>

  <ng-container matColumnDef="view">
    <th mat-header-cell *matHeaderCellDef>View</th>
    <td mat-cell *matCellDef="let element">
      <a mat-button [href]="renderInvoiceURL(element.id, 'sk')">SK</a>
      <a mat-button [href]="renderInvoiceURL(element.id, 'en')">EN</a>
    </td>
  </ng-container>

  <ng-container matColumnDef="download">
    <th mat-header-cell *matHeaderCellDef>Download</th>
    <td mat-cell *matCellDef="let element">
      <a mat-button [href]="downloadInvoiceURL(element.id, 'sk')">SK</a>
      <a mat-button [href]="downloadInvoiceURL(element.id, 'en')">EN</a>
    </td>
  </ng-container>

  <ng-container matColumnDef="options">
    <th mat-header-cell *matHeaderCellDef>Options</th>
    <td mat-cell *matCellDef="let element">
      <a mat-button [routerLink]="['/invoices/duplicate', element.id]" queryParamsHandling="merge">Duplicate</a>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>

<mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[10, 20, 30]" [length]="total" showFirstLastButtons>
</mat-paginator>
