import { Inject, Injectable } from '@angular/core'
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http'
import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'
import { DOCUMENT } from '@angular/common'
import { environment } from '../environments/environment'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  private buildReturnToURL(): string {
    const params = new HttpParams().set(
      'return_to',
      this.document.location.href
    )
    return environment.authUrl + '?' + params.toString()
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap(
        () => {},
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.document.location.href = this.buildReturnToURL()
              return
            }
          }
        }
      )
    )
  }
}
