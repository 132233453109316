import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { RecipeDetailComponent } from './components/recipe-detail/recipe-detail.component'
import { RecipesComponent } from './components/recipes/recipes.component'
import { EventsComponent } from './components/events/events.component'
import { PaymentsComponent } from './components/payments/payments.component'
import { PaymentStatsComponent } from './components/payment-stats/payment-stats.component'
import { InvoicesComponent } from './components/invoices/invoices.component'
import { PaymentEditComponent } from './components/payment-edit/payment-edit.component'
import { RecipeEditComponent } from './components/recipe-edit/recipe-edit.component'
import { BirthdaysComponent } from './components/birthdays/birthdays.component'
import { EstatesComponent } from './components/estates/estates.component'
import { DashboardComponent } from './components/dashboard/dashboard.component'
import { InvoiceDuplicateComponent } from './components/invoice-duplicate/invoice-duplicate.component'

const routes: Routes = [
  { path: '', component: DashboardComponent },
  { path: 'payments/edit/:id', component: PaymentEditComponent },
  { path: 'payments/stats', component: PaymentStatsComponent },
  { path: 'payments', component: PaymentsComponent },
  { path: 'events', component: EventsComponent },
  { path: 'recipes', component: RecipesComponent },
  { path: 'recipes/edit/:id', component: RecipeEditComponent },
  { path: 'recipes/:id', component: RecipeDetailComponent },
  { path: 'invoices/duplicate/:id', component: InvoiceDuplicateComponent },
  { path: 'invoices', component: InvoicesComponent },
  { path: 'birthdays', component: BirthdaysComponent },
  { path: 'estates', component: EstatesComponent },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
