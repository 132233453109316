<table mat-table [dataSource]="estates">
  <ng-container matColumnDef="title">
    <th mat-header-cell *matHeaderCellDef>Title</th>
    <td mat-cell *matCellDef="let element">
      <span class="searcher">{{ element.searcher }}</span
      >&nbsp;
      <a href="{{ element.url }}" target="_blank">{{ element.title }}</a>
    </td>
  </ng-container>

  <ng-container matColumnDef="place">
    <th mat-header-cell *matHeaderCellDef>Place</th>
    <td mat-cell *matCellDef="let element">{{ element.place }}</td>
  </ng-container>

  <ng-container matColumnDef="price">
    <th mat-header-cell *matHeaderCellDef>Price</th>
    <td mat-cell *matCellDef="let element">{{ element.price }} &euro;</td>
  </ng-container>

  <ng-container matColumnDef="published">
    <th mat-header-cell *matHeaderCellDef>Published</th>
    <td mat-cell *matCellDef="let element">
      {{ element.published | date: 'dd.MM.yyyy' }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>

<mat-paginator
  [pageSize]="pageSize"
  [pageSizeOptions]="[10, 20, 30]"
  [length]="total"
  showFirstLastButtons
>
</mat-paginator>
