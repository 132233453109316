import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiUtil } from '../api-util'
import { Invoice } from '../models/invoice.model'
import { Pagination } from '../pagination'

export class InvoiceResponse {
  invoice: Invoice
}

export class InvoicesResponse {
  invoices: Invoice[]
  count: number
}

@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  constructor(private http: HttpClient) {}

  getInvoice(id: number): Observable<InvoiceResponse> {
    return this.http.get<InvoiceResponse>(ApiUtil.buildURL('/api/invoices/' + id), ApiUtil.buildOptions())
  }

  getInvoices(pagination: Pagination): Observable<InvoicesResponse> {
    const params = pagination.httpParams()
    return this.http.get<InvoicesResponse>(ApiUtil.buildURL('/api/invoices'), ApiUtil.buildOptions(params))
  }

  duplicateInvoice(id: number, data: Record<string, unknown>): Observable<any> {
    return this.http.post(ApiUtil.buildURL('/api/invoices/' + id + '/duplicate'), data, ApiUtil.buildOptions())
  }
}
