<table mat-table [dataSource]="birthdays">
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let element">{{ element.name }}</td>
  </ng-container>

  <ng-container matColumnDef="born_on">
    <th mat-header-cell *matHeaderCellDef>Born on</th>
    <td mat-cell *matCellDef="let element">
      {{ element.born_on | date: 'dd.MM.yyyy' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="age">
    <th mat-header-cell *matHeaderCellDef>Age</th>
    <td mat-cell *matCellDef="let element">
      {{ element.age }}
    </td>
  </ng-container>

  <ng-container matColumnDef="next">
    <th mat-header-cell *matHeaderCellDef>Next</th>
    <td mat-cell *matCellDef="let element">
      will turn {{ +element.age + 1 }} on {{ element.born_on | date: 'dd.MM' }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>

<mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[10, 20, 30]" [length]="total" showFirstLastButtons>
</mat-paginator>
