<div class="grid grid-cols-12">
  <div class="col-span-12">
    <form [formGroup]="duplicateForm" (ngSubmit)="onSubmit()">
      <mat-form-field appearance="fill">
        <mat-label>Number</mat-label>
        <textarea matInput formControlName="number"></textarea>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Issue date</mat-label>
        <input matInput [matDatepicker]="issue_date" formControlName="issue_date" />
        <mat-datepicker-toggle matSuffix [for]="issue_date"></mat-datepicker-toggle>
        <mat-datepicker #issue_date></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Due date</mat-label>
        <input matInput [matDatepicker]="due_date" formControlName="due_date" />
        <mat-datepicker-toggle matSuffix [for]="due_date"></mat-datepicker-toggle>
        <mat-datepicker #due_date></mat-datepicker>
      </mat-form-field>

      <button type="submit" [disabled]="!duplicateForm.valid">Submit</button>
    </form>
  </div>
</div>
