<div class="grid grid-cols-12">
  <div class="col-span-2">
    <app-payment-filter
      [start]="start"
      [end]="end"
      [accountIds]="accountIds"
      [categoryIds]="categoryIds"
      [total]="sum"
      (filterChangeEvent)="updateFilter($event)"
    ></app-payment-filter>
  </div>
  <div class="col-span-10">
    <app-payment-list
      [payments]="payments"
      [pageIndex]="pageIndex"
      [pageSize]="pageSize"
      [total]="total"
      (pageChangeEvent)="updatePage($event)"
    ></app-payment-list>
  </div>
</div>
