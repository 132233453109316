<div class="grid grid-cols-12">
  <div class="col-span-12">
    <form [formGroup]="editForm" (ngSubmit)="onSubmit()">
      <mat-form-field appearance="fill">
        <mat-label>Title</mat-label>
        <textarea matInput formControlName="title"></textarea>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Description</mat-label>
        <textarea matInput formControlName="description"></textarea>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Amount</mat-label>
        <input matInput formControlName="amount" type="text" />
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Source</mat-label>
        <input matInput formControlName="source" type="text" />
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Happened on</mat-label>
        <input matInput formControlName="happened_on" type="text" />
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Variable symbol</mat-label>
        <textarea matInput formControlName="variable_symbol"></textarea>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Constant symbol</mat-label>
        <textarea matInput formControlName="constant_symbol"></textarea>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Specific symbol</mat-label>
        <textarea matInput formControlName="specific_symbol"></textarea>
      </mat-form-field>

      <button type="submit" [disabled]="!editForm.valid">Submit</button>
    </form>
  </div>
</div>
