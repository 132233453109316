import { Component, OnInit, ViewChild } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { tap } from 'rxjs/operators'
import { Birthday } from 'src/app/models/birthday.model'
import { Pagination } from 'src/app/pagination'
import { BirthdayService, BirthdaysResponse } from 'src/app/services/birthday.service'

@Component({
  selector: 'app-birthday-list',
  templateUrl: './birthday-list.component.html',
  styleUrls: ['./birthday-list.component.scss'],
})
export class BirthdayListComponent implements OnInit {
  displayedColumns: string[] = ['name', 'born_on', 'age', 'next']
  pageSize: number = 30
  total: number
  birthdays: Birthday[]

  @ViewChild(MatPaginator) paginator: MatPaginator

  constructor(private birthdayService: BirthdayService) {}

  ngOnInit(): void {
    this.getBirthdays(0, this.pageSize)
  }

  ngAfterViewInit(): void {
    this.paginator.page.pipe(tap(() => this.getBirthdaysPage())).subscribe()
  }

  getBirthdaysPage(): void {
    this.getBirthdays(this.paginator.pageIndex, this.paginator.pageSize)
  }

  getBirthdays(pageIndex: number, pageSize: number): void {
    this.birthdayService.getBirthdays(new Pagination(pageIndex + 1, pageSize)).subscribe((resp: BirthdaysResponse) => {
      this.birthdays = resp.birthdays
      this.total = resp.count
    })
  }
}
