<div class="grid grid-cols-12">
  <div class="col-span-2">
    <app-payment-filter
      [start]="start"
      [end]="end"
      [accountIds]="accountIds"
      [categoryIds]="categoryIds"
      [total]="sum"
      (filterChangeEvent)="updateFilter($event)"
    ></app-payment-filter>
  </div>
  <div class="col-span-10">
    <canvas
      baseChart
      [type]="chartType"
      [datasets]="chartData"
      [labels]="chartLabels"
      [options]="chartOptions"
      [legend]="chartLegend"
    ></canvas>
  </div>
</div>
