<div style="height: 94vh">
  <mat-toolbar color="primary">
    <mat-toolbar-row>
      <button mat-icon-button (click)="sidenav.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
      <span>Lifeman</span>
      <span class="menu-spacer"></span>
      <div>
        <a mat-button [routerLink]="'/events'">Events</a>
        <a mat-button [routerLink]="'/recipes'">Recipes</a>
        <a mat-button [routerLink]="'/payments'">Payments</a>
        <a mat-button [routerLink]="'/invoices'">Invoices</a>
        <a mat-button [routerLink]="'/estates'">Estates</a>
        <a mat-button [routerLink]="'/birthdays'">Birthdays</a>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>

  <mat-sidenav-container>
    <mat-sidenav #sidenav>
      <mat-nav-list>
        <a (click)="sidenav.toggle()" href="" mat-list-item>Close</a>
        <a mat-list-item [routerLink]="'/events'">Events</a>
        <a mat-list-item [routerLink]="'/recipes'">Recipes</a>
        <a mat-list-item [routerLink]="'/payments'">Payments</a>
        <a mat-list-item [routerLink]="'/invoices'">Invoices</a>
        <a mat-list-item [routerLink]="'/estates'">Estates</a>
        <a mat-list-item [routerLink]="'/birthdays'">Birthdays</a>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content><router-outlet></router-outlet></mat-sidenav-content>
  </mat-sidenav-container>
</div>
