import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { MatPaginator, PageEvent } from '@angular/material/paginator'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { tap } from 'rxjs/operators'
import { Payment } from '../../models/payment.model'
import { PaymentAddComponent } from '../payment-add/payment-add.component'

@Component({
  selector: 'app-payment-list',
  templateUrl: './payment-list.component.html',
  styleUrls: ['./payment-list.component.scss'],
})
export class PaymentListComponent implements AfterViewInit {
  displayedColumns: string[] = ['amount', 'title', 'description', 'happened_on', 'account', 'options']

  @Input() pageIndex: number
  @Input() pageSize: number
  @Input() total: number
  @Input() payments: Payment[]
  @Output() pageChangeEvent = new EventEmitter<PageEvent>()
  @ViewChild(MatPaginator) paginator: MatPaginator

  constructor(public dialog: MatDialog) {}

  ngAfterViewInit(): void {
    this.paginator.page.pipe(tap((event: PageEvent) => this.pageChangeEvent.emit(event))).subscribe()
  }

  openPaymentDialog() {
    const dialogRef = this.dialog.open(PaymentAddComponent, {
      width: '250px',
      data: {},
    })
    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed')
    })
  }

  parseFloat = parseFloat
}
