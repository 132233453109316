import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { NestedTreeControl } from '@angular/cdk/tree'
import {
  RecipeCategoryNode,
  RecipeCategoryTreeResponse,
  RecipeService,
} from '../../services/recipe.service'
import { MatTreeNestedDataSource } from '@angular/material/tree'

@Component({
  selector: 'app-recipe-category-tree',
  templateUrl: './recipe-category-tree.component.html',
  styleUrls: ['./recipe-category-tree.component.scss'],
})
export class RecipeCategoryTreeComponent implements OnInit {
  treeControl = new NestedTreeControl<RecipeCategoryNode>(
    (node: RecipeCategoryNode) => node.children
  )
  treeDataSource = new MatTreeNestedDataSource<RecipeCategoryNode>()

  @Output() categoryIdChangeEvent = new EventEmitter<number>()

  constructor(private recipeService: RecipeService) {}

  updateCategoryId(node: RecipeCategoryNode): void {
    this.categoryIdChangeEvent.emit(node.id)
  }

  hasChild = (_: number, node: RecipeCategoryNode) => node.children.length > 0

  ngOnInit(): void {
    this.getCategoryTree()
  }

  getCategoryTree(): void {
    this.recipeService
      .getCategoryTree()
      .subscribe((resp: RecipeCategoryTreeResponse) => {
        this.treeDataSource.data = resp.nodes
      })
  }
}
