import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs'
import { ApiUtil } from '../api-util'
import { PaymentAccount } from '../models/payment-account.model'
import { PaymentCategory } from '../models/payment-category.model'
import { Payment } from '../models/payment.model'
import { Pagination } from '../pagination'
import { PaymentStat } from '../models/payment-stat'

export class PaymentAccountsResponse {
  accounts: PaymentAccount[]
}

export class PaymentCategoriesResponse {
  categories: PaymentCategory[]
}

export class PaymentResponse {
  payment: Payment
}

export class PaymentsResponse {
  payments: Payment[]
  count: number
}

export class PaymentTotalResponse {
  total: number
}

export enum PaymentStatsGrouping {
  Month = 0,
  Year = 1,
}

export class PaymentTotalOptions {
  constructor(
    public start: Date,
    public end: Date,
    public accountIds: number[],
    public categoryIds: number[]
  ) {}
}

export class PaymentsOptions {
  constructor(
    public pagination: Pagination,
    public start?: Date,
    public end?: Date,
    public accountIds?: number[],
    public categoryIds?: number[]
  ) {}
}

export class PaymentStatsOptions {
  constructor(
    public start: Date,
    public end: Date,
    public accountIds: number[],
    public categoryIds: number[],
    public grouping: PaymentStatsGrouping = PaymentStatsGrouping.Year
  ) {}
}

export class PaymentStatsResponse {
  stats: PaymentStat[]
}

@Injectable({
  providedIn: 'root',
})
export class PaymentsService {
  constructor(private http: HttpClient) {}

  getPaymentAccounts(
    pagination: Pagination
  ): Observable<PaymentAccountsResponse> {
    const params = pagination.httpParams().set('active', 'true')
    return this.http.get<PaymentAccountsResponse>(
      ApiUtil.buildURL('/api/payment-accounts'),
      ApiUtil.buildOptions(params)
    )
  }

  getPaymentCategories(
    pagination: Pagination
  ): Observable<PaymentCategoriesResponse> {
    const params = pagination.httpParams()
    return this.http.get<PaymentCategoriesResponse>(
      ApiUtil.buildURL('/api/payment-categories'),
      ApiUtil.buildOptions(params)
    )
  }

  getPayment(id: number): Observable<PaymentResponse> {
    return this.http.get<PaymentResponse>(
      ApiUtil.buildURL('/api/payments/' + id),
      ApiUtil.buildOptions()
    )
  }

  updatePayment(id: number, data: Record<string, unknown>): Observable<any> {
    return this.http.put(
      ApiUtil.buildURL('/api/payments/' + id),
      data,
      ApiUtil.buildOptions()
    )
  }

  getPayments(opts: PaymentsOptions): Observable<PaymentsResponse> {
    let params = opts.pagination.httpParams()
    if (opts.start) {
      params = params.append('start', opts.start.toISOString())
    }
    if (opts.end) {
      params = params.append('end', opts.end.toISOString())
    }
    if (opts.accountIds) {
      for (const accountId of opts.accountIds) {
        params = params.append('account_ids', accountId.toString())
      }
    }
    if (opts.categoryIds) {
      for (const categoryId of opts.categoryIds) {
        params = params.append('category_ids', categoryId.toString())
      }
    }
    return this.http.get<PaymentsResponse>(
      ApiUtil.buildURL('/api/payments'),
      ApiUtil.buildOptions(params)
    )
  }

  getPaymentStats(opts: PaymentStatsOptions) {
    let params = new HttpParams().set('grouping', opts.grouping)
    if (opts.start) {
      params = params.append('start', opts.start.toISOString())
    }
    if (opts.end) {
      params = params.append('end', opts.end.toISOString())
    }
    if (opts.accountIds) {
      for (const accountId of opts.accountIds) {
        params = params.append('account_ids', accountId.toString())
      }
    }
    if (opts.categoryIds) {
      for (const categoryId of opts.categoryIds) {
        params = params.append('category_ids', categoryId.toString())
      }
    }
    return this.http.get<PaymentStatsResponse>(
      ApiUtil.buildURL('/api/payments/stats'),
      ApiUtil.buildOptions(params)
    )
  }

  getPaymentTotal(opts: PaymentTotalOptions): Observable<PaymentTotalResponse> {
    let params = new HttpParams()
    if (opts.start) {
      params = params.append('start', opts.start.toISOString())
    }
    if (opts.end) {
      params = params.append('end', opts.end.toISOString())
    }
    if (opts.accountIds) {
      for (const accountId of opts.accountIds) {
        params = params.append('account_ids', accountId.toString())
      }
    }
    if (opts.categoryIds) {
      for (const categoryId of opts.categoryIds) {
        params = params.append('category_ids', categoryId.toString())
      }
    }
    return this.http.get<PaymentTotalResponse>(
      ApiUtil.buildURL('/api/payments/total'),
      ApiUtil.buildOptions(params)
    )
  }
}
