import { Component, OnInit, ViewChild } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { tap } from 'rxjs/operators'
import { Estate } from 'src/app/models/estate.model'
import { Pagination } from 'src/app/pagination'
import { EstateResponse, EstateService } from 'src/app/services/estate.service'

@Component({
  selector: 'app-estate-list',
  templateUrl: './estate-list.component.html',
  styleUrls: ['./estate-list.component.scss'],
})
export class EstateListComponent implements OnInit {
  displayedColumns: string[] = ['title', 'place', 'price', 'published']
  pageSize: number = 30
  total: number
  estates: Estate[]

  @ViewChild(MatPaginator) paginator: MatPaginator

  constructor(private estateService: EstateService) {}

  ngOnInit(): void {
    this.getEstates(0, this.pageSize)
  }

  ngAfterViewInit(): void {
    this.paginator.page.pipe(tap(() => this.getEstatesPage())).subscribe()
  }

  getEstatesPage(): void {
    this.getEstates(this.paginator.pageIndex, this.paginator.pageSize)
  }

  getEstates(pageIndex: number, pageSize: number): void {
    this.estateService
      .getEstates(new Pagination(pageIndex + 1, pageSize))
      .subscribe((resp: EstateResponse) => {
        this.estates = resp.estates
        this.total = resp.count
      })
  }
}
