import { environment } from '../environments/environment'
import { HttpHeaders, HttpParams } from '@angular/common/http'

export class ApiUtil {
  static defaultHttpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    withCredentials: true,
  }

  static buildURL(path: string, params?: HttpParams): string {
    let query = ''
    if (params) {
      query = '?' + params.toString()
    }
    return environment.apiUrl + path + query
  }

  static buildOptions(params?: HttpParams): Record<string, unknown> {
    return {
      ...this.defaultHttpOptions,
      params,
    }
  }
}
