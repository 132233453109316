import { Component, OnInit } from '@angular/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import { EventsResponse, EventsService } from '../../services/events.service'
import { Event } from '../../models/event.model'

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
})
export class EventsComponent implements OnInit {
  calendarOptions = {
    plugins: [dayGridPlugin],
    firstDay: 1,
    editable: false,
    initialView: 'dayGridMonth',
    events: undefined,
    fixedWeekCount: false,
  }

  constructor(private eventsService: EventsService) {}

  ngOnInit(): void {
    this.getEvents()
  }

  getEvents(): void {
    this.calendarOptions.events = (
      info: { start: Date; end: Date },
      successCallback: (arg0: Record<string, unknown>[]) => void
    ) => {
      this.eventsService.getEvents(info.start, info.end).subscribe((resp: EventsResponse) => {
        successCallback(resp.events.map((event: Event) => this.buildEvent(event)))
      })
    }
  }

  buildEvent(event: Event): Record<string, unknown> {
    return {
      title: event.title,
      start: event.start,
      end: event.end,
      borderColor: event.calendar.info.border,
      allDay: event.all_day,
    }
  }
}
