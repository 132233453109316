<div class="grid grid-cols-12">
  <div class="col-span-12">
    <h2>Upcoming events</h2>
    <table>
      <tr>
        <th>Calendar</th>
        <th>Title</th>
        <th>Date</th>
      </tr>
      <tr *ngFor="let event of events">
        <td>
          <span class="calendar">{{ event.calendar.title }}</span>
        </td>
        <td>
          {{ event.title }}
        </td>
        <td>{{ event.start | date : 'dd.MM.yyyy' }}</td>
      </tr>
    </table>
  </div>
</div>
