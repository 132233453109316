import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { RecipeResponse, RecipeService } from '../../services/recipe.service'
import { Recipe } from '../../models/recipe.model'

@Component({
  selector: 'app-recipe-detail',
  templateUrl: './recipe-detail.component.html',
  styleUrls: ['./recipe-detail.component.scss'],
})
export class RecipeDetailComponent implements OnInit {
  recipe: Recipe

  constructor(
    private route: ActivatedRoute,
    private recipeService: RecipeService
  ) {}

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id')
    this.getRecipe(id)
  }

  getRecipe(id: string) {
    this.recipeService
      .getRecipe(id)
      .subscribe((resp: RecipeResponse) => (this.recipe = resp.recipe))
  }
}
