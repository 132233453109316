<form (submit)="$event.preventDefault()">
  <mat-form-field>
    <mat-label>Search</mat-label>
    <input matInput type="text" [formControl]="search" />
  </mat-form-field>
</form>

<table mat-table [dataSource]="recipes">
  <ng-container matColumnDef="title">
    <th mat-header-cell *matHeaderCellDef>Title</th>
    <td mat-cell *matCellDef="let element">
      <a [routerLink]="['/recipes', element.id]">{{ element.title }}</a>
    </td>
  </ng-container>

  <ng-container matColumnDef="category_title">
    <th mat-header-cell *matHeaderCellDef>Category</th>
    <td mat-cell *matCellDef="let element">{{ element.category_title }}</td>
  </ng-container>

  <ng-container matColumnDef="options">
    <th mat-header-cell *matHeaderCellDef>Options</th>
    <td mat-cell *matCellDef="let row">
      <a mat-button [routerLink]="['/recipes/edit', row.id]">Edit</a>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>

<mat-paginator
  [pageIndex]="pageIndex"
  [pageSize]="pageSize"
  [pageSizeOptions]="[10, 20, 30]"
  [length]="total"
  showFirstLastButtons
>
</mat-paginator>
