import { Injectable } from '@angular/core'
import { Recipe } from '../models/recipe.model'
import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { ApiUtil } from '../api-util'
import { Pagination } from '../pagination'
import { RecipeCategory } from '../models/recipe-category.model'

export class RecipeResponse {
  recipe: Recipe
}

export class RecipesResponse {
  recipes: Recipe[]
  count: number
}

export class RecipeCategoryNode {
  id: number
  title: string
  children: RecipeCategoryNode[]
}

export class RecipeCategoryResponse {
  category: RecipeCategory
}

export class RecipeCategoryTreeResponse {
  nodes: RecipeCategoryNode[]
}

export class RecipeCategoriesResponse {
  categories: RecipeCategory[]
  count: number
}

@Injectable({
  providedIn: 'root',
})
export class RecipeService {
  constructor(private http: HttpClient) {}

  getRecipe(id: string): Observable<RecipeResponse> {
    return this.http.get<RecipeResponse>(
      ApiUtil.buildURL('/api/recipes/' + id),
      ApiUtil.buildOptions()
    )
  }

  updateRecipe(id: number, data: Record<string, unknown>): Observable<any> {
    return this.http.put(
      ApiUtil.buildURL('/api/recipes/' + id),
      data,
      ApiUtil.buildOptions()
    )
  }

  getRecipes(
    pagination: Pagination,
    search?: string,
    categoryId?: number
  ): Observable<RecipesResponse> {
    let params = pagination.httpParams()
    if (search) {
      params = params.set('search', search)
    }
    if (categoryId) {
      params = params.set('category_id', categoryId.toString())
    }
    return this.http.get<RecipesResponse>(
      ApiUtil.buildURL('/api/recipes'),
      ApiUtil.buildOptions(params)
    )
  }

  getCategoryTree(): Observable<RecipeCategoryTreeResponse> {
    return this.http.get<RecipeCategoryTreeResponse>(
      ApiUtil.buildURL('/api/recipe-categories/tree'),
      ApiUtil.buildOptions()
    )
  }

  getCategory(id: string): Observable<RecipeCategoryResponse> {
    return this.http.get<RecipeCategoryResponse>(
      ApiUtil.buildURL('/api/recipe-categories/' + id),
      ApiUtil.buildOptions()
    )
  }

  getCategories(pagination: Pagination): Observable<RecipeCategoriesResponse> {
    return this.http.get<RecipeCategoriesResponse>(
      ApiUtil.buildURL('/api/recipe-categories'),
      ApiUtil.buildOptions(pagination.httpParams())
    )
  }
}
