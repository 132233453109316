import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiUtil } from '../api-util'
import { Birthday } from '../models/birthday.model'
import { Pagination } from '../pagination'

export class BirthdaysResponse {
  birthdays: Birthday[]
  count: number
}

@Injectable({
  providedIn: 'root',
})
export class BirthdayService {
  constructor(private http: HttpClient) {}

  getBirthdays(pagination: Pagination): Observable<BirthdaysResponse> {
    const params = pagination.httpParams()
    return this.http.get<BirthdaysResponse>(
      ApiUtil.buildURL('/api/birthdays'),
      ApiUtil.buildOptions(params)
    )
  }
}
