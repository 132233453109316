import { Component, OnInit } from '@angular/core'
import { UntypedFormBuilder } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { FormUtil } from 'src/app/form-util'
import { Recipe } from 'src/app/models/recipe.model'
import { RecipeResponse, RecipeService } from 'src/app/services/recipe.service'

@Component({
  selector: 'app-recipe-edit',
  templateUrl: './recipe-edit.component.html',
  styleUrls: ['./recipe-edit.component.scss'],
})
export class RecipeEditComponent implements OnInit {
  editForm = this.fb.group({
    title: [''],
    content: [''],
  })
  recipe: Recipe

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private fb: UntypedFormBuilder,
    private recipeService: RecipeService
  ) {}

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id')
    this.getRecipe(id)
  }

  getRecipe(id: string) {
    this.recipeService.getRecipe(id).subscribe((resp: RecipeResponse) => {
      this.recipe = resp.recipe
      this.fillForm()
    })
  }

  fillForm() {
    this.editForm.patchValue({
      title: this.recipe.title,
      content: this.recipe.content,
    })
  }

  onSubmit() {
    const values = FormUtil.getDirtyValues(this.editForm)
    this.recipeService.updateRecipe(this.recipe.id, values).subscribe((res) => {
      this.router.navigate(['/recipes', this.recipe.id])
    })
  }
}
