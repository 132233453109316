import { Component, OnInit } from '@angular/core'
import { Location } from '@angular/common'
import { ActivatedRoute, ParamMap, Router } from '@angular/router'
import { ChartOptions, ChartType, ChartDataset } from 'chart.js'
import { PaymentFilter } from 'src/app/models/payment-filter.model'
import { PaymentStat } from 'src/app/models/payment-stat'
import {
  PaymentsService,
  PaymentStatsGrouping,
  PaymentStatsOptions,
  PaymentStatsResponse,
  PaymentTotalOptions,
  PaymentTotalResponse,
} from 'src/app/services/payments.service'

@Component({
  selector: 'app-payment-stats',
  templateUrl: './payment-stats.component.html',
  styleUrls: ['./payment-stats.component.scss'],
})
export class PaymentStatsComponent implements OnInit {
  chartData: ChartDataset[]
  chartLabels: string[]
  chartOptions: ChartOptions & { annotation: any } = {
    responsive: true,
    annotation: {
      annotations: [
        {
          type: 'line',
          mode: 'vertical',
          scaleID: 'x-axis-0',
          value: 'March',
          borderColor: 'orange',
          borderWidth: 2,
          label: {
            enabled: true,
            fontColor: 'orange',
            content: 'LineAnno',
          },
        },
      ],
    },
  }
  chartLegend = true
  chartType: ChartType = 'line'

  start: Date
  end: Date
  categoryIds: number[]
  accountIds: number[]
  stats: PaymentStat[]
  sum: number

  constructor(
    private readonly location: Location,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private paymentsService: PaymentsService
  ) {}

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((params: ParamMap) => {
      this.accountIds = this.getAccountIds(params)
      this.categoryIds = this.getCategoryIds(params)
      this.start = this.getStart(params)
      this.end = this.getEnd(params)
      this.loadPaymentStats()
    })
  }

  loadPaymentStats() {
    this.location.go(this.buildURL())
    this.getPaymentStats()
    this.getPaymentTotal()
  }

  updateFilter(filter: PaymentFilter) {
    this.start = filter.start
    this.end = filter.end
    this.accountIds = filter.accountIds
    this.categoryIds = filter.categoryIds
    this.loadPaymentStats()
  }

  getStart(params: ParamMap): Date {
    return this.getDateFromParam(params, 'start')
  }

  getEnd(params: ParamMap): Date {
    return this.getDateFromParam(params, 'end')
  }

  getCategoryIds(params: ParamMap): number[] {
    return this.getIdsFromParam(params, 'cat')
  }

  getAccountIds(params: ParamMap): number[] {
    return this.getIdsFromParam(params, 'acc')
  }

  getIdsFromParam(params: ParamMap, param: string): number[] {
    return (params.get(param) || '')
      .split(',')
      .filter((v) => v != '')
      .map((v) => Number(v))
      .filter((v) => !Number.isNaN(v))
  }

  getDateFromParam(params: ParamMap, param: string): Date {
    const s = params.get(param) || ''
    if (!s) {
      return null
    }
    const d = new Date(s)
    try {
      d.toISOString()
    } catch {
      return null
    }
    return d
  }

  getPaymentStats(): void {
    this.paymentsService
      .getPaymentStats(
        new PaymentStatsOptions(this.start, this.end, this.accountIds, this.categoryIds, PaymentStatsGrouping.Year)
      )
      .subscribe((resp: PaymentStatsResponse) => {
        this.stats = resp.stats
        this.buildChartData(true)
      })
  }

  getPaymentTotal(): void {
    const now = new Date()
    this.paymentsService
      .getPaymentTotal(new PaymentTotalOptions(null, null, this.accountIds, this.categoryIds))
      .subscribe((resp: PaymentTotalResponse) => {
        this.sum = resp.total
      })
  }

  buildChartData(running: boolean): void {
    let labels = []
    let data = []
    let temp = 0
    this.stats.forEach((v: PaymentStat) => {
      labels.push(v.key)
      let actual = Number(v.value)
      temp += actual
      if (running) {
        data.push(temp)
      } else {
        data.push(actual)
      }
    })
    this.chartLabels = labels
    this.chartData = [{ data: data, label: 'Payments' }]
  }

  buildURL(): string {
    const queryParams = {
      acc: this.accountIds.join(','),
      cat: this.categoryIds.join(','),
      start: this.start ? this.start.toISOString() : '',
      end: this.end ? this.end.toISOString() : '',
    }
    return this.router
      .createUrlTree([], {
        relativeTo: this.route,
        queryParams: queryParams,
        queryParamsHandling: 'merge',
      })
      .toString()
  }
}
