import { UntypedFormArray, UntypedFormGroup } from '@angular/forms'

export class FormUtil {
  static getValues(form: UntypedFormGroup | UntypedFormArray): Record<string, unknown> {
    const values = {}
    Object.keys(form.controls).forEach((key) => {
      const currentControl = form.controls[key]
      if (currentControl instanceof UntypedFormArray) {
        values[key] = this.getValues(currentControl)
      } else {
        values[key] = currentControl.value
      }
    })
    return values
  }

  static getDirtyValues(form: UntypedFormGroup | UntypedFormArray): Record<string, unknown> {
    const values = {}
    Object.keys(form.controls).forEach((key) => {
      const currentControl = form.controls[key]
      if (currentControl.dirty) {
        if (currentControl instanceof UntypedFormArray) {
          values[key] = this.getDirtyValues(currentControl)
        } else {
          values[key] = currentControl.value
        }
      }
    })
    return values
  }
}
