import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { UntypedFormBuilder } from '@angular/forms'
import { Payment } from '../../models/payment.model'
import { PaymentResponse, PaymentsService } from '../../services/payments.service'
import { FormUtil } from 'src/app/form-util'

@Component({
  selector: 'app-payment-edit',
  templateUrl: './payment-edit.component.html',
  styleUrls: ['./payment-edit.component.scss'],
})
export class PaymentEditComponent implements OnInit {
  editForm = this.fb.group({
    title: [''],
    description: [''],
    amount: [''],
    variable_symbol: [''],
    constant_symbol: [''],
    specific_symbol: [''],
    source: [''],
    happened_on: [''],
  })
  payment: Payment

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private fb: UntypedFormBuilder,
    private paymentsService: PaymentsService
  ) {}

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id')
    this.getPayment(+id)
  }

  getPayment(id: number): void {
    this.paymentsService.getPayment(id).subscribe((resp: PaymentResponse) => {
      this.payment = resp.payment
      this.fillForm()
    })
  }

  fillForm() {
    this.editForm.patchValue({
      title: this.payment.title,
      description: this.payment.description,
      amount: this.payment.amount,
      variable_symbol: this.payment.variable_symbol,
      constant_symbol: this.payment.constant_symbol,
      specific_symbol: this.payment.specific_symbol,
      source: this.payment.source,
      happened_on: this.payment.happened_on,
    })
  }

  onSubmit() {
    const values = FormUtil.getDirtyValues(this.editForm)
    this.paymentsService.updatePayment(this.payment.id, values).subscribe((res) => {
      this.router.navigate(['/payments'], { queryParamsHandling: 'preserve' })
    })
  }
}
