import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiUtil } from '../api-util'
import { Estate } from '../models/estate.model'
import { Pagination } from '../pagination'

export class EstateResponse {
  estates: Estate[]
  count: number
}

@Injectable({
  providedIn: 'root',
})
export class EstateService {
  constructor(private http: HttpClient) {}

  getEstates(pagination: Pagination): Observable<EstateResponse> {
    const params = pagination.httpParams()
    return this.http.get<EstateResponse>(
      ApiUtil.buildURL('/api/estates'),
      ApiUtil.buildOptions(params)
    )
  }
}
